<template>
    <div class="dropdown">
        <button @click="toggleDropdown" class="dropdown-button flex align-center">
            <div class="wp-100 hp-100 flex align-center justify-center" style="margin-right: 7px;">
                <img :src="selectedOption.img" alt="timezone icon" class="dropdown-arrow" style="width: 24px; height: 24px;" />
            </div>
            <span>{{ selectedOption.label }}</span>
        </button>

        <div v-if="isOpen" class="dropdown-menu">
            <div 
              v-for="option in dropdownTimezoneOptions"
              :key="option.value" 
              class="dropdown-item flex align-center"
              @click="selectOption(option)"
            >
              <!-- <img :src="option.img" alt="" class="dropdown-item-img" /> -->
              <span>{{ option.label }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode'

export default {
    // props: {
    //     options: {
    //         type: Array,
    //         required: true
    //     }
    // },
    data() {
        return {
            isOpen: false,
            selectedOption: { label: "UTC", img: require("/src/assets/imgs/timezone.png") },
            language: '',
            timezone: '',
            // 타임존 설정
            dropdownTimezoneOptions: [],
        };
    },
    // created() {
    //
    //
    // },
    mounted() {
        this.listTimezones();
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },

        // 사용자 시간대 목록 조회
        async listTimezones(){
            const imgPath = require("/src/assets/imgs/timezone.png");
            try {
                const response = await fetch(`/api/web/users/timezones`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if(response.status === 200) {
                    const responseData = await response.json();
                    this.timezones = responseData;

                    for (let i = 0; i < this.timezones.length; i++) {
                        const label = this.timezones[i];
                        const value = label.split("/").pop().toLowerCase(); // 마지막 요소를 소문자로 사용

                        this.dropdownTimezoneOptions.push({
                            value: value,
                            label: label,
                            img: imgPath,
                        });
                    }
                    this.savedOptions();
                }
            } catch (error){
                console.error('Error fetching API data:', error);
            }
        },

        savedOptions(){
            // localStorage에서 selectedTimezone를 가져옴
            const storedTimezone = localStorage.getItem("selectedTimezone");
            if (storedTimezone) {
                // 저장된 언어값에 맞는 selectedOption을 설정
                const selectedOption = this.dropdownTimezoneOptions?.find(option => option.value === storedTimezone);
                if (selectedOption) {
                    this.selectedOption = selectedOption;
                }
                this.selectedOption.label = storedTimezone;
            } else {
                // localStorage에 값이 없다면 기본값으로 한글을 설정
                this.selectedOption = this.dropdownTimezoneOptions.find(option => option.label === 'UTC');
            }
        },

        // 클릭한 option의 label을 기준으로 언어 설정
        selectOption(option) {
            // console.log('option:',option);

            // const newLang = String(option.value).toLowerCase() === 'kr' ? 'KR' : 'EN';
            const newTimezone= option.value;

            // 언어 설정 메서드를 호출하여 새로운 언어로 변경
            this.$setTimezone(newTimezone);

            localStorage.setItem('selectedTimezone', newTimezone);

            // selectedOption 업데이트
            this.selectedOption = option;
            this.isOpen = false;

            if(option.value === 'utc') {
                this.timezone = 'UTC';
            } else if(option.value === 'new_york') {
                this.timezone = 'America/New_York';
            } else if(option.value === 'seoul') {
                this.timezone = 'Asia/Seoul';
            } else if(option.value === 'dubai') {
                this.timezone = 'Asia/Dubai';
            }

            this.changeTimezone(this.timezone);
        },

        // 토큰 갱신 함수
        refreshToken(newToken) {
            document.cookie = `token=${newToken}; path=/; max-age=3600`;
        },

        // 타임존 변경 api
        async changeTimezone(timezone){
            try {
                const response =  await fetch(`/api/web/users/timezone`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        timezone: timezone
                    })
                });

                if(response.status === 200) {
                    const responseData = await response.json();
                    const decodeJWTtokenData = VueJwtDecode.decode(responseData);

                    const updateSessionData = {
                        isLogin: true,
                        token: responseData,
                        userInfo: {
                            authenticationType: decodeJWTtokenData.authentication_type ?? '-',
                            departmentId: decodeJWTtokenData.department_id ?? '-',
                            departmentName: !decodeJWTtokenData.department_name ? '-':decodeURIComponent(escape(decodeJWTtokenData.department_name)),
                            organizationId: decodeJWTtokenData.organization_id ?? '-',
                            organizationName: !decodeJWTtokenData.organization_name ? '-':decodeURIComponent(escape(decodeJWTtokenData.organization_name)),
                            permissionType: decodeJWTtokenData.permission_type ?? '-',
                            userId: decodeJWTtokenData.user_id ?? '-',
                            userName: !decodeJWTtokenData.user_name ? '-': decodeURIComponent(escape(decodeJWTtokenData.user_name)),
                            job: !decodeJWTtokenData.job ? '-': decodeURIComponent(escape(decodeJWTtokenData.job)),
                            email: !decodeJWTtokenData.email ? '-': decodeURIComponent(escape(decodeJWTtokenData.email)),
                            phone: !decodeJWTtokenData.phone_nmbr ? '-': decodeURIComponent(escape(decodeJWTtokenData.phone_nmbr)),
                            chargeDepartments: !decodeJWTtokenData.charge_departments ? '-': decodeURIComponent(escape(decodeJWTtokenData.charge_departments)),
                            language: decodeJWTtokenData.language ?? '-',
                            timezone: decodeJWTtokenData.timezone ?? '-'
                        },
                        // expiresAt: new Date(decodeJWTtokenData.exp * 1000)    // 밀리초로 변환
                        expiresAt: new Date(decodeJWTtokenData.exp * 1000)    // 밀리초로 변환
                    };
                    sessionStorage.setItem('session', JSON.stringify(updateSessionData));

                    localStorage.setItem('selectedTimezone', decodeJWTtokenData.timezone);

                    this.refreshToken(responseData);

                    if(window.location.pathname === '/' || window.location.pathname.includes('/patient')) {
                        window.location.reload();
                    }
                }

            } catch(error) {
                console.error('Error fetching API data:', error);
            }
        },
    }
};
</script>

<style scoped>
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdown-button {
    border: none;
    cursor: pointer;
    min-width: 100px;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    color: #000;
    padding: 0 15px;
    transition: .2s;
}
.dropdown-button:hover {
    background-color: #f1f1f1;
}
.dropdown-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    /* width: 100%; */
    min-width: 130px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 5px 0;
    font-size: 15px;
}
.dropdown-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
}
.dropdown-item:hover {
    background-color: #f1f1f1;
}
.dropdown-item-img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}
</style>
