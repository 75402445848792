<style scoped>
/* card */
.card-alarm {
    border-radius: 8px;
    background-color: #FFDDDD;
    margin-bottom: 4px;
}
.card-alarm:hover {
    background-color: #FFCCCC;
}
.card-alarm:last-child {
    margin-bottom: 0;
}
.card-alarm.card-noti {
    background-color: #fff;
    margin-bottom: 16px;
}
.card-alarm.card-noti .wrap-date {
    background-color: #EEEEEE;
    color: #002244;
    padding: 12px 8px;
    border-bottom: 1px solid #DDDDDD
}
.card-alarm.card-noti .inner-card-alarm {
    border-bottom: 1px solid #DDDDDD;
}
.card-alarm.card-noti .card-content {
    color: #0066BB;
    text-decoration: underline;
    margin-right: 4px;
    white-space: break-spaces;
}
.card-alarm.card-noti .card-noti-kind {
    color: #0066BB;   
}
.inner-card-alarm {
    cursor: pointer;
}
.inner-card-alarm-read {
    background-color: #EEEEEE;
    border-radius: 8px;
    color: #888888;
}
.inner-card-alarm-read:hover {
    background-color: #DDDDDD;
}
.card-alarm-content {
    display: block;
    width: 100%;
    white-space: break-spaces;
    line-height: 1.1;
}
</style>

<script>

export default {
    props: {
        data: Object,
        elementClass: String,
        isFix: String,
    },
    methods: {
        // 알람/알림 읽음처리
        async checkAlarm(event){
            const alarmId = event.currentTarget.closest("[data-id]").getAttribute("data-id");
            const senderId = event.currentTarget.closest("[data-senderId]").getAttribute("data-senderId");
            try {
                const response = await fetch(`/api/web/notifications/check/${alarmId}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ids: alarmId
                    })
                });
                
                const responseData = await response.json();
                if(responseData === true) {
                    window.location.replace(`/patient/${senderId}`);
                } 
            } catch (error) {
                console.error('Error fetching API data:', error);
            }
        },
    }
};
</script>

<template>
    <div :class="['card-alarm color-point-blue', { 'card-noti': elementClass === 'card-noti' }]">
        <template v-if="elementClass !== 'card-noti'">
            <template v-if="isFix === 'fixed' || 'all'">
                <div @click="checkAlarm" :data-id="data.id" :data-senderId="data.senderId">
                    <div :class="['flex flex-between align-center inner-card-alarm', {'inner-card-alarm-read': data.checkYn === true}]" style="padding: 12px 20px 12px 8px;" @click="e=>e.preventDefault()">
                        <div class="flex">
                            <span class="fs-14" style="margin-right: 4px;">{{ data.receiveDt.substr(11,5) }}.</span>
                            <div>
                                <div class="flex mb-1 fs-14">
                                    {{ data.sender }}
                                </div>
                                <div v-if="elementClass === 'card-noti'">
                                    <b class="card-content">{{$translate('post_meal_blood_sugar')}}</b>
                                    <span style="color: #004488;">162mg/dL</span>
                                </div>
                                <div v-else>
                                    <b class="card-content card-alarm-content">{{ data.content }}</b>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img v-if="data.notificationType === '부정맥'" style="width:32px;height:32px;" :src="require('/src/assets/imgs/patient_detail_observe_electro.png')" alt="부정맥 아이콘 이미지" />
                            <img v-else-if="data.notificationType === '연속심전도'" style="width:32px;height:32px;" :src="require('/src/assets/imgs/patient_detail_observe_electro.png')" alt="연속심전도 아이콘 이미지" />
                            <img v-else-if="data.notificationType === '체온'" style="width:32px;height:32px;" :src="require('/src/assets/imgs/patient_detail_observe_temperature.png')" alt="체온 아이콘 이미지" />
                            <img v-else-if="data.notificationType === '연속체온'" style="width:32px;height:32px;" :src="require('/src/assets/imgs/patient_detail_observe_temperature.png')" alt="연속체온 아이콘 이미지" />
                            <img v-else-if="data.notificationType === '혈압'" style="width:32px;height:32px;" :src="require('/src/assets/imgs/patient_detail_observe_bloodpressure.png')" alt="혈압 아이콘 이미지" />
                            <img v-else-if="data.notificationType === '복약'" style="width:32px;height:32px;" :src="require('/src/assets/imgs/patient_detail_observe_medicine.png')" alt="복약 아이콘 이미지" />
                        </div>
                    </div>
                </div>
            </template>
        </template>

        <template v-else>
            <div v-if="elementClass === 'card-noti'">
                <div class="wrap-date">
                    <b>{{ data.receiveDate }}</b>
                </div>
            </div>
            <template v-for="(item, index) in data.notificationList" :key="index">
                <div @click="checkAlarm" :data-id="item.id" :data-senderId="item.senderId">
                    <div v-if="elementClass === 'card-noti'">
                        <div class="flex flex-between align-center inner-card-alarm" style="padding: 16px 8px;">
                            <div class="flex">
                                <span class="fs-14" style="margin-right: 4px;">{{ item.receiveDt.substr(11,5) }}.</span>
                                <div>
                                    <div class="flex mb-1 fs-14">
                                        {{ item.sender }}
                                    </div>
                                    <div>
                                        <b class="card-content">{{ item.title }}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>