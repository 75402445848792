<template>
  <v-app class="hp-100">
    <v-main class="hp-100">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
