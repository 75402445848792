<template>
    <div class="dropdown">
        <button @click="toggleDropdown" class="dropdown-button flex align-center">
            <img :src="selectedOption.img" alt="" class="dropdown-item-img" />
            <span>{{ selectedOption.label }}</span>
            <!-- <img :src="imageSource" alt="arrow" class="dropdown-arrow" /> -->
        </button>

        <div v-if="isOpen" class="dropdown-menu">
            <div 
              v-for="option in dropdownOptions"
              :key="option.value" 
              class="dropdown-item flex align-center"
              @click="selectOption(option)"
            >
              <img :src="option.img" alt="" class="dropdown-item-img" />
              <span>{{ option.label }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode'

export default {
    // props: {
    //     options: {
    //         type: Array,
    //         required: true
    //     }
    // },
    data() {
        return {
            isOpen: false,
            selectedOption: { label: "한글", img: require("/src/assets/imgs/south-korea.png") },
            languages: '',
            language: '',
            timezone: '',
            // 언어 설정
            dropdownOptions: [],
        };
    },
    mounted() {
        this.listLanguages();
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },

        // 사용자 사용 언어 목록 조회
        async listLanguages(){
            const imageMapping = {
                ko: require("/src/assets/imgs/south-korea.png"), // 한국어 이미지
                en: require("/src/assets/imgs/america.png") // 영어 이미지
            };

            try {
                const response = await fetch(`/api/web/users/languages`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });


                if(response.status === 200) {
                    const responseData = await response.json();
                    this.languages = responseData;
                    this.dropdownOptions = this.languages.map(item => {
                        let lang = 'en';
                        if(item.key == "ko") {
                            lang = 'KR';
                        } else {
                            lang = 'EN';
                        }
                        return {
                            // value: item.key, // 'ko' -> 'KR', 'en' -> 'EN'
                            value: lang, // 'ko' -> 'KR', 'en' -> 'EN'
                            label: item.value, // '한국어' or 'English'
                            img: imageMapping[item.key] // 이미지 경로 매핑
                        };
                    });
                    this.savedLanguages();
                }
            } catch(error) {
                console.error('Error fetching API data:', error);
            }
        },

        savedLanguages(){
            // // localStorage에서 selectedLanguage를 가져옴
            const storedLanguage = localStorage.getItem("selectedLanguage");

            if (storedLanguage) {
                // 저장된 언어값에 맞는 selectedOption을 설정
                const selectedOption = this.dropdownOptions.find(option => option.value === storedLanguage);
                if (selectedOption) {
                    this.selectedOption = {
                        ...selectedOption,
                        value: storedLanguage === 'ko' ? 'KR' : 'EN'
                    };
                }
            } else {
                // localStorage에 값이 없다면 기본값으로 한글을 설정
                this.selectedOption = this.dropdownOptions.find(option => option.label === '한글');
            }
        },

        // 클릭한 option의 label을 기준으로 언어 설정
        selectOption(option) {
            const newLang = String(option.value).toLowerCase() === 'kr' ? 'KR' : 'EN';

            // 언어 설정 메서드를 호출하여 새로운 언어로 변경
            this.$setLang(newLang);

            localStorage.setItem('selectedLanguage', newLang);

            // selectedOption 업데이트
            this.selectedOption = option;
            this.isOpen = false;

            if(option.value === 'KR') {
                this.language = 'kr';
                // this.timezone = 'UTC';
            } else if(option.value === 'EN') {
                this.language = 'en';
                // this.timezone = 'America/New_York';
            }

            // this.changeTimezone(this.timezone);
            this.changeLanguage(this.language);
        },

        // 토큰 갱신 함수
        refreshToken(newToken) {
            document.cookie = `token=${newToken}; path=/; max-age=3600`;
        },

        // 언어 변경 api
        async changeLanguage(language){
            try {
                const response = await fetch(`/api/web/users/language`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        language: language.toLowerCase() == 'kr' ? 'ko': language
                    })
                });

                if(response.status === 200) {
                    const responseData = await response.json();
                    const decodeJWTtokenData = VueJwtDecode.decode(responseData);

                    const updateSessionData = {
                        isLogin: true,
                        token: responseData,
                        userInfo: {
                            authenticationType: decodeJWTtokenData.authentication_type ?? '-',
                            departmentId: decodeJWTtokenData.department_id ?? '-',
                            departmentName: !decodeJWTtokenData.department_name ? '-':decodeURIComponent(escape(decodeJWTtokenData.department_name)),
                            organizationId: decodeJWTtokenData.organization_id ?? '-',
                            organizationName: !decodeJWTtokenData.organization_name ? '-':decodeURIComponent(escape(decodeJWTtokenData.organization_name)),
                            permissionType: decodeJWTtokenData.permission_type ?? '-',
                            userId: decodeJWTtokenData.user_id ?? '-',
                            userName: !decodeJWTtokenData.user_name ? '-': decodeURIComponent(escape(decodeJWTtokenData.user_name)),
                            job: !decodeJWTtokenData.job ? '-': decodeURIComponent(escape(decodeJWTtokenData.job)),
                            email: !decodeJWTtokenData.email ? '-': decodeURIComponent(escape(decodeJWTtokenData.email)),
                            phone: !decodeJWTtokenData.phone_nmbr ? '-': decodeURIComponent(escape(decodeJWTtokenData.phone_nmbr)),
                            chargeDepartments: !decodeJWTtokenData.charge_departments ? '-': decodeURIComponent(escape(decodeJWTtokenData.charge_departments)),
                            language: decodeJWTtokenData.language ?? '-',
                            timezone: decodeJWTtokenData.timezone ?? '-'
                        },
                        // expiresAt: new Date(decodeJWTtokenData.exp * 1000)    // 밀리초로 변환
                        expiresAt: new Date(decodeJWTtokenData.exp * 1000)    // 밀리초로 변환
                    };
                    sessionStorage.setItem('session', JSON.stringify(updateSessionData));

                    localStorage.setItem('selectedLanguage', decodeJWTtokenData.language == 'ko' ? 'KR': 'EN');

                    this.refreshToken(responseData);

                    if(window.location.pathname === '/' || window.location.pathname.includes('/patient')) {
                        window.location.reload();
                    }
                }

            } catch(error) {
                console.error('Error fetching API data:', error);
            }
        },

        // 타임존 변경 api
        async changeTimezone(timezone){
            try {
                const response =  await fetch(`/api/web/users/timezone`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        timezone: timezone
                    })
                });

                if(response.status === 200) {
                    const responseData = await response.json();
                    const decodeJWTtokenData = VueJwtDecode.decode(responseData);

                    const updateSessionData = {
                        isLogin: true,
                        token: responseData,
                        userInfo: {
                            authenticationType: decodeJWTtokenData.authentication_type ?? '-',
                            departmentId: decodeJWTtokenData.department_id ?? '-',
                            departmentName: !decodeJWTtokenData.department_name ? '-':decodeURIComponent(escape(decodeJWTtokenData.department_name)),
                            organizationId: decodeJWTtokenData.organization_id ?? '-',
                            organizationName: !decodeJWTtokenData.organization_name ? '-':decodeURIComponent(escape(decodeJWTtokenData.organization_name)),
                            permissionType: decodeJWTtokenData.permission_type ?? '-',
                            userId: decodeJWTtokenData.user_id ?? '-',
                            userName: !decodeJWTtokenData.user_name ? '-': decodeURIComponent(escape(decodeJWTtokenData.user_name)),
                            job: !decodeJWTtokenData.job ? '-': decodeURIComponent(escape(decodeJWTtokenData.job)),
                            email: !decodeJWTtokenData.email ? '-': decodeURIComponent(escape(decodeJWTtokenData.email)),
                            phone: !decodeJWTtokenData.phone_nmbr ? '-': decodeURIComponent(escape(decodeJWTtokenData.phone_nmbr)),
                            chargeDepartments: !decodeJWTtokenData.charge_departments ? '-': decodeURIComponent(escape(decodeJWTtokenData.charge_departments)),
                            language: decodeJWTtokenData.language ?? '-',
                            timezone: decodeJWTtokenData.timezone ?? '-'
                        },
                        // expiresAt: new Date(decodeJWTtokenData.exp * 1000)    // 밀리초로 변환
                        expiresAt: new Date(decodeJWTtokenData.exp * 1000)    // 밀리초로 변환
                    };
                    sessionStorage.setItem('session', JSON.stringify(updateSessionData));

                    this.refreshToken(responseData);

                }

            } catch(error) {
                console.error('Error fetching API data:', error);
            }
        },
    }
};
</script>

<style scoped>
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdown-button {
    border: none;
    cursor: pointer;
    min-width: 100px;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    color: #000;
    padding: 0 15px;
    transition: .2s;
}
.dropdown-button:hover {
    background-color: #f1f1f1;
}
.dropdown-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    /* width: 100%; */
    width: 130px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 5px 0;
}
.dropdown-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
}
.dropdown-item:hover {
    background-color: #f1f1f1;
}
.dropdown-item-img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}
</style>
