// localStorage에서 특정 항목들을 제외하고 모두 삭제하는 함수
export function clearLocalStorageExcept(exceptKeys = []) {
    // exceptKeys가 배열인지 확인
    if (!Array.isArray(exceptKeys)) {
        console.error('exceptKeys must be an array');
        return;
    }

    for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        // exceptKeys 배열에 포함되지 않은 키만 삭제
        if (!exceptKeys.includes(key)) {
            localStorage.removeItem(key);
        }
    }
}
