import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n';
// import { loadFonts } from './plugins/webfontloader'

import '@/assets/css/style.css'

import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables.min.js';
// import 'datatables.net-dt/css/jquery.dataTables.css';

window.$ = $;
window.DataTable = require('datatables.net-dt');

// loadFonts()
// const cors = require('cors')
createApp(App)
  .use(router)
  .use(i18n)
  .use(vuetify)
  // .use(cors({credentials: true}))

  // .use(
  //   cors({
  //     // front 서버인 127.0.0.1:8080 의 요청을 허용하도록 cors 사용
  //     origin: [process.env.FRONT_URL_1, process.env.FRONT_URL_2],
  //     methods: ["GET", "PUT", "POST", "PATCH", "DELETE", "OPTIONS"],
  //     optionsSuccessStatus: 200,
  //     credentials: true,
  //   })
  // )
  .mount('#app')
  