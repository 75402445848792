import { ref } from 'vue';
import translations from '../locales/translation.json';

export default {
    install(app) {
        // 언어 상태 관리
        const lang = ref(localStorage.getItem('selectedLanguage') || 'EN');
        const timezone = ref(localStorage.getItem('selectedTimezone') || 'UTC');

        // 번역 함수 설정
        app.config.globalProperties.$translate = (key, params = {}) => {
            const translation = translations.find(item => item["Unique Key"] === key);
            if (!translation) return key;
            // const text = translation[lang.value] || translation["KR"];
            const text = translation[lang.value] || translation["EN"];

            return text.replace(/{(\w+)}/g, (_, match) => params[match] || '');
        };

        // 전역에서 사용 가능하도록 셋팅
        app.config.globalProperties.$lang = lang;
        app.config.globalProperties.$setLang = (newLang) => {
            lang.value = newLang;
            localStorage.setItem('selectedLanguage', newLang);
        };

        // 타임존 전역에서 사용 가능하도록 설정
        app.config.globalProperties.$setTimezone = (newTimezone) => {
            if (typeof newTimezone !== 'string' || newTimezone.trim() === '') {
                // console.error('Invalid timezone:', newTimezone);
                return;
            }
            
            timezone.value = newTimezone.trim();
            localStorage.setItem('selectedTimezone', timezone.value);
        };
    }
};
